<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex align-items-center " >
            <div class="row mb-2 ">
              <label for="date" class="col-md-4 form-label">
                  From Date
                 
              </label>
              <div class="col-md-8">
                  <flat-pickr v-model="schedule.from_date" class="form-control"
                      @on-change="changeFromDate(schedule.from_date, schedule.to_date)"
                      placeholder="Select From Date" name="date" :config="flatPickrDateConfig">
                  </flat-pickr>
              </div>
            </div>
            <div class="row mb-2 ms-2">
                <label for="date" class="col-md-4 form-label">
                    To Date
                    
                </label>
                <div class="col-md-8">
                    <flat-pickr v-model="schedule.to_date" class="form-control"
                        @on-change="changeToDate(schedule.from_date, schedule.to_date)"
                        placeholder="Select To Date" name="date" :config="flatPickrDateConfig">
                    </flat-pickr>
                </div>
            </div>

            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Behavior Assessment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="d-flex justify-content-end">
                  <router-link :to="{ name: 'hrd-ojt-assessment-create' }">
                    <button
                      v-show="!loading"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                      v-if="hasPermissions(['module-group-create'])"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add Record
                    </button>
                  </router-link>
                </div> -->
            
            <!-- advance serach -->
            <div class="d-flex mb-3">
                      <div class="d-flex flex-column me-2">
                          <label class="col-form-label">Program</label>
                          <div style="width: 250px">
                              <v-select label="name" v-model="program" :options="programs" :clearable="false"
                                  :selectable="(options) => program.short_name != options.short_name "
                                  @option:selected="findBatch_programFilter(program)">
                              </v-select>
                          </div>
                      </div>
                      <div class="d-flex flex-column me-2">
                          <label class="col-form-label">Batch</label>
                          <div style="width: 180px">
                              <v-select label="batch_no" v-model="batch" :options="batchs" :clearable="false"
                              :selectable="(options) =>  batch.id != options.id "
                              @option:selected="FilterData(program, batch)"
                              >
                              </v-select>
                          </div>
                      </div>
                      <div class="d-flex mt-4">
                          <!-- <div class="col-form-label d-none">hfhd</div> -->
                          <div @click="refreshData()" class="icon-css">
                              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                          </div>
                      </div> 
                  </div>  
                <!--end advance serach -->

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="behavior-assessment-list-datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>Total Score</th>
                    <th>Assessor</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template
                    v-for="(item, index) in behaviorAssessment"
                    :key="index"
                  >
                    <tr>
                      <td>{{ ++index }}</td>
                      <td>{{ item.employee_name }}</td>
                      <td>{{ item.program_name }}</td>
                      <td>{{ item.program_batch_no }}</td>
                      <td>
                        {{ item.total_score }}
                      </td>
                      <td>
                        {{
                          item.assessor_employee_name
                        }}
                      </td>

                      <td>
                        <span>
                          <router-link
                            class="action-icon"
                            :to="{
                              name: 'hrd-behavior-assessment-detail',
                              params: { id: item.id , assessorId: item.assessor_employee_id},
                            }"
                          >
                            <button
                              type="button"
                              class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                            >
                              <i class="fas fa-eye"></i>
                              View
                            </button>
                          </router-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  components: {
    vSelect,
    flatPickr,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      behaviorAssessment: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      programs: this.$store.getters["odoo/getAllPrograms"],
      batchs : [],
      program : "",
      batch : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
      flatPickrDateConfig: {
          minDate: null,
          maxDate: null,
          defaultDate: null,
      },
      flatPickrDateConfigLimit: {
          minDate: null,
          maxDate: null,
          defaultDate: null,
      },
      flatPickrTimeConfig: {
          enableTime: true,
          noCalendar: true,
          defaultTime: "08:00:00",
          dateFormat: "H:i:s",
          altInput: true,
          altFormat: "H:i",
          minTime: "08:00",
          maxTime: "17:00",
      },
      schedule: {
          from_date: "",
          to_date: "",
      },
    };
  },
  methods: {
    isSelectableYear(options) {
      if(!this.year) return true;
      return this.year != options.id;
    },
    isSelectableMonth(options) {
      if(!this.month) return true;
      return this.month != options.id;
    },
    getTotalScore(array) {
      let totalScore = 0;
      array.forEach((item) => {
        totalScore += item.score;
      });
      return totalScore;
    },
    async getAllBehaviorAssessment() {
      this.loading = true;
      this.$Progress.start();

      let url;

      if(this.schedule.from_date && this.schedule.to_date){
        url = `${this.baseUrl}admin/v2/behavior-assessment?from_date=${this.schedule.from_date}&to_date=${this.schedule.to_date}`;
      }else if(this.schedule.from_date){
        url = `${this.baseUrl}admin/v2/behavior-assessment?from_date=${this.schedule.from_date}`;
      }else{
        url = `${this.baseUrl}admin/v2/behavior-assessment`;
      }


      await axios
        .get(url)
        .then((response) => {
          this.behaviorAssessment = response.data.data;
          this.loading = false;
          this.$Progress.finish();
          // console.log(this.behaviorAssessment);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#behavior-assessment-list-datatable").DataTable().draw();
    },

    async findBatch_programFilter(program) {
            this.batch = "";
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program.id}`)
                .then((response) => {
                    this.batchs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Batch!");
                });
    
                this.clearFilter();
                $.fn.dataTable.ext.search.push(function (settings, data) {
                    const check_program = program?.name == data[2] ? true : false;
                    // console.log('checl-prog', check_program);
                    return check_program ? 1 : 0;
                });
                $("#behavior-assessment-list-datatable").DataTable().draw(); 
        },
    FilterData(program, batch) {
            this.clearFilter();
            // alert('reach');
            $.fn.dataTable.ext.search.push(function (settings, data) {
                const check_program = program?.name == data[2] ? true : false;
                const check_batch = batch?.batch_no == data[3] ? true : false;
                
                return (check_program && check_batch) ? true : false;
            });
            $("#behavior-assessment-list-datatable").DataTable().draw(); 
        },
    refreshData() {
      (this.behaviorAssessment = []), (this.month = "");
      this.year = "";
      this.months = [];
      this.years = [];
      this.yearId = "";
      this.batchs = [];
      this.batch = "";
      this.program = "";
      this.schedule.from_date = '';
      this.schedule.to_date = '';
      this.clearFilter();
      this.getAllBehaviorAssessment();
    },

    changeFromDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;
      this.getAllBehaviorAssessment();
    },

    changeToDate(from_date, to_date) {
      this.schedule.from_date = from_date;
      this.schedule.to_date = to_date;
      this.getAllBehaviorAssessment();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#behavior-assessment-list-datatable").DataTable().draw();
    },

  },
  created() {
    this.clearFilter();
    this.getAllBehaviorAssessment();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
.status {
  padding: 0.4em;
}
.viewDetail {
  background-color: #0e69a2;
  color: white;
  font-weight: bold;
  font-size: 0.7em;
  padding: 0.6em;
  border: 1px solid #0e69a2;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
}
.viewDetail:hover {
  background-color: transparent;
  color: #0e69a2;
}
</style>
